<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('close-dialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <div v-if="!verifyInfo">
          <v-row class="justify-end">
            <v-btn icon color="primary" @click="$emit('close-dialog')">
              <v-icon v-text="'close'" />
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-1 px-3">
              <h2 class="d-block mb-4" v-text="$t('project.academy.users.competitorData')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.email" outlined dense :label="$t('project.academy.users.competitorEmail')"
                :error-messages="getFieldErrors('email')"
                :disabled="!!(!$v.form.dontHaveEmail.$error && form.dontHaveEmail)"
                @blur="$v.form.email.$touch()"
              />
            </v-col>
            <v-col cols="12" class="pt-0 pb-1 px-3">
              <v-checkbox
                v-model="form.dontHaveEmail" class="mt-0" :label="$t('project.academy.users.dontHaveEmail')"
                :disabled="!!($v.form.email.$error && form.email)"
                @change="form.email = form.dontHaveEmail ? null : form.email"
              />
            </v-col>
          </v-row>
          <v-card-actions class="justify-center">
            <v-btn :disabled="$v.form.$invalid" color="primary" min-width="12rem" @click="verify" v-text="$t('common.continue')" />
          </v-card-actions>
        </div>
        <email-exists
          v-if="verifyInfo && user.email" :organization-id="organizationId"
          :project-id="projectId" :user="user" @close-dialog="$emit('close-dialog')"
        />
        <no-email
          v-if="verifyInfo && !user.email" :organization-id="organizationId" :dont-have-email="form.dontHaveEmail"
          :email="form.email" :project-id="projectId" @close-dialog="$emit('close-dialog')"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { requiredIf, email } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AddPlayerDialog',
  components: {
    EmailExists: () => import('@/modules/projectUsers/AddPlayerDialog/EmailExists'),
    NoEmail: () => import('@/modules/projectUsers/AddPlayerDialog/NoEmail'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
    emailsPlayers: { type: Array, default: () => [] },
  },
  data: () => ({
    form: { email: null, dontHaveEmail: false },
    user: { email: null },
    verifyInfo: false,
  }),
  validations() {
    return {
      form: {
        email: { required: requiredIf(() => !this.form.dontHaveEmail), email, isUnique: value => !this.emailsPlayers.includes(value) },
        dontHaveEmail: { required: requiredIf(() => !this.form.email) },
      },
    }
  },
  methods: {
    async verify() {
      if (this.form.email) {
        const user = await this.$store.dispatch('user/readByEmail', this.form.email)
        if (user) {
          const { id, firstName, lastName } = user
          this.user = { id, email: user.email, firstName, lastName }
        }
      }
      this.verifyInfo = true
    },
  },
}
</script>
