import { render, staticRenderFns } from "./AddPlayerDialog.vue?vue&type=template&id=4d76312c&"
import script from "./AddPlayerDialog.vue?vue&type=script&lang=js&"
export * from "./AddPlayerDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports